(function(){
    var s = document.createElement('script'),
    e = !document.body ? document.querySelector('head') :
    document.body;
    s.src = 'https://eu.acsbapp.com/apps/app/dist/js/app.js';
    s.async = true;
    s.onload = function(){
    acsbJS.init({
    statementLink : '#',
    footerHtml : '<a href="https://www.accessiway.fr/" target="_blank">AccessiWay. The Web Accessibility Solution</a>',
    hideMobile : false,
    hideTrigger : false,
    language : 'fr',
    position : 'left',
    leadColor : '#B8985F',
    triggerColor : '#B8985F',
    triggerRadius : '50%',
    triggerPositionX : 'left',
    triggerPositionY : 'bottom',
    triggerIcon : 'people',
    triggerSize : 'medium',
    triggerOffsetX : 20,
    triggerOffsetY : 20,
    mobile : {
    triggerSize : 'medium',
    triggerPositionX : 'left',
    triggerPositionY : 'bottom',
    triggerOffsetX : 10,
    triggerOffsetY : 10,
    triggerRadius : '50%'
    }
    });
    };
    e.appendChild(s);
}());
